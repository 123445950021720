import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import { HttpStatus } from '../../util/http';

// This middleware captures errors from rejected RTK fetch queries
// and logs them to the console and PostHog.
export const errorMiddleware: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action) && isRTKQueryAction(action)) {
    const { method, url } = action.meta.baseQueryMeta.request;
    const { status, data } = action.payload;
    const path = new URL(url).pathname;
    const message = data?.message || 'Unknown error';

    if (shouldCaptureError({ method, path, status, message })) {
      const error = new Error(`API error (${status}): ${method} ${path} - ${message}`);
      console.error(error);
      posthog.captureException(error, { action });
    }
  }

  try {
    return next(action);
  } catch (error) {
    console.error(error);
    posthog.captureException(error, { action });
    throw error;
  }
};

const shouldCaptureError = ({
  status
}: {
  method: string;
  path: string;
  status: number | string;
  message?: string;
}): boolean => {
  return (
    // Thrown when a fetch is cancelled mid-flight e.g. the user signed out.
    status !== 'FETCH_ERROR' &&
    // The application handles unauthorized errors.
    status !== HttpStatus.Unauthorized &&
    // The application handles not found errors.
    status !== HttpStatus.NotFound
  );
};

const isRTKQueryAction = (obj: object): obj is RTKQueryAction => {
  const action = obj as { payload: { status?: number }; meta: { baseQueryMeta?: object } };
  return 'payload' in action && 'status' in action.payload && 'meta' in action && 'baseQueryMeta' in action.meta;
};

interface RTKQueryAction {
  payload: {
    status: number;
    data?: { message?: string };
  };
  meta: {
    baseQueryMeta: {
      request: {
        method: string;
        url: string;
      };
    };
  };
}
