import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { redirectToLogout } from '../auth';
import { RootState } from '../store';
import { resetAll } from '../store/actions';
import { getRefreshToken, setToken } from '../store/slices/auth';
import { HttpStatus } from '../util/http';
import { Token } from './auth';

export type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

// Refreshes the access token and performs a retry when a request errors with 401 Unauthorized.
export const withReauth = (baseQuery: BaseQuery): BaseQuery => {
  // To prevent multiple concurrent reauth attempts.
  const reauthMutex = new Mutex();

  return async (args, api, options) => {
    // Wait for any ongoing reauth attempt to finish.
    await reauthMutex.waitForUnlock();

    // Try the initial request.
    let res = await baseQuery(args, api, options);
    if (res.error?.status !== HttpStatus.Unauthorized) {
      return res;
    }

    // Wait for and use an existing reauth attempt if it exists.
    if (reauthMutex.isLocked()) {
      await reauthMutex.waitForUnlock();
      return baseQuery(args, api, options);
    }

    // Acquire the lock, reauth, and retry the request.
    const release = await reauthMutex.acquire();
    try {
      const logOut = () => {
        api.dispatch(resetAll());
        redirectToLogout();
        return res;
      };

      const refreshToken = getRefreshToken(api.getState() as RootState);
      if (!refreshToken) {
        return logOut();
      }

      res = await baseQuery(
        {
          method: 'POST',
          url: '/authorize/token/refresh',
          body: { refreshToken }
        },
        api,
        options
      );
      if (res.error || !res.data) {
        return logOut();
      }

      api.dispatch(setToken({ ...(res.data as Token), refreshToken }));
      return baseQuery(args, api, options);
    } finally {
      release();
    }
  };
};
