import { tms } from './tms';

export type Token = {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  idToken: string;
};

export type ExchangeAuthorizationCode = {
  authorizationCode: string;
  state: string;
  redirectUri?: string;
};

export type ConfirmResetPassword = {
  userId: string;
  confirmationCode: string;
  password: string;
  passwordConfirmation: string;
};

export type VerifyEmail = {
  code: string;
  email: string;
};

const auth = tms.injectEndpoints({
  endpoints: (builder) => ({
    exchangeAuthorizationCode: builder.mutation<Token, ExchangeAuthorizationCode>({
      query: ({ redirectUri, ...body }) => ({
        url: `authorize/token${redirectUri ? `?redirect_uri=${encodeURIComponent(redirectUri)}` : ''}`,
        method: 'POST',
        body: body
      })
    }),

    resetPassword: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}/password`,
        method: 'DELETE'
      })
    }),

    confirmResetPassword: builder.mutation<void, ConfirmResetPassword>({
      query: ({ userId, confirmationCode, password }) => ({
        url: `users/${userId}/password`,
        method: 'POST',
        body: { confirmationCode, password }
      })
    }),

    verifyEmail: builder.mutation<void, VerifyEmail>({
      query: (body) => ({
        url: `verify-email`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Profile', 'Users']
    })
  }),
  overrideExisting: false
});

export const {
  useExchangeAuthorizationCodeMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useVerifyEmailMutation
} = auth;
